
import styled from "styled-components";
import scrollBG from './assets/scroll/scrollBG.png';
import lock from './assets/op/lock.png'

const Container=styled.div`
width:100% ;
position:relative ;
height:100vh;
padding-top:80px ;
background-color:#101010 ;
/* height:calc(100vh - 80px) ; */
/* margin-top:80px ; */

 `

const Inner=styled.div`
width:1280px ;
margin:0 auto ;
position:relative ;

@media screen and (max-width:1400px){
   padding:0 70px ;
   box-sizing:border-box ;
}
@media screen and (max-width:1280px){
   width:100% ;
   padding:0px ;
}

@media screen and (max-width:950px){
   main{
    width:100% ;
    display:flex ;
    justify-content:center ;
   }
}

 `
const Desc=styled.div`
  width:570px ;
  padding-top:87px ;
  text-align:left ;
  font-size:56px ;
  font-family: 'Roboto-Medium';
  color: #fff;
  line-height: 64px;
  z-index:10;

  i{
    font-style:normal ;
  }

  aside{
    padding-right:76px ;
  }

  @media screen and (max-width:1300px){
    width:475px ;
    font-size:46px ;
    line-height: 54px;
  }

  @media screen and (max-width:1280px){
   padding-left:117px ;
   box-sizing:content-box ;
  }


 @media screen and (max-width:1180px){
    width:395px ;
    font-size:42px ;
    line-height: 54px;

    aside{
    padding-right:26px ;
  }
 }
 @media screen and (max-width:1000px){
  padding-left:80px ;
 }
 @media screen and (max-width:950px){
    box-sizing:border-box ;
    padding-left:0px ;
 }

 @media screen and (max-width:750px){
  width:290px;
  font-size:30px ;
  }

`

// const PurpleText=styled.div`
// color:#60DFFF;
// font-family: 'Galyon-Book';
// text-align:left ;
// white-space: pre-wrap;
// `
const LineaBg=styled.img` 
position:absolute ;
right:0 ;
top:49px;

width:608px ;
height:auto;

@media screen and (max-width:1600px){
  width:531px ;
}
@media screen and (max-width:1400px){
  width:498px ;
  right:70px ;
}
/* @media screen and (max-width:1450px){
  width:498px ;
} */

@media screen and (max-width:1200px){
    width:400px ;
    right:120px;
    height:auto ;
}
@media screen and (max-width:1060px){
    width:380px ;
    right:110px;
    height:auto ;
}
@media screen and (max-width:1000px){
    width:380px ;
    right:70px;
    height:auto ;
}

@media screen and (max-width:950px){
   display:none ;
}
`
const LineaBg2=styled.img`
width:405px ;
height:auto ;
margin-top:-25px ;

display:none ;
opacity:0.6 ;

@media screen and (max-width:950px){
   display:inline-block;
}
@media screen and (max-width:430px){
  width:350px ;
}
`
const Data=styled.div`
position:absolute ;
bottom:62px ;
margin-top:203px;
text-align:left ;

@media screen and (max-width:750px){
    bottom:39px ;
}
@media screen and (max-width:450px){
    bottom:100px ;
}
/* padding-top:25% ; */
 `

const Footer=styled.div`
/* display:inline-flex ; */
width:100% ;
margin-top: 14px;
font-size: 17px;
font-family: 'Roboto-Regular';
font-weight: normal;
color: #FFFFFF;
line-height: 60px;
display:flex ;
align-items:center ;
padding:23px 5vw 0 5vw;
/* white-space:pre-wrap ; */
white-space:nowrap;
word-spacing:5px;

&>img{
  width:30px ;
  height:auto ;
}

&>mark::before{
  content: "&";
  font-family: 'Galyon-Book';
  margin:0 10px ;
}

@media screen and (max-width:750px){
    font-size:14px ;
    line-height:13px ;

    img:first-child{
      width:25px ;
    }
    img:last-child{
      //height:14px !important;
      margin-bottom:3px !important ;
    }
}
 `
export function Linea(props){
  const {onEnterNextPage}=props;
    return (
        <Container>
            <Inner>
            <main>
                <Desc>
                    Omnichain <br />
                    <section style={{display:"flex",alignItems:'center'}}>
                            <mark style={{color:"#EB7104",background:"transparent",whiteSpace:"pre-wrap"}}>naming for </mark>
                            wallets
                    </section>
                    <aside style={{width:"100%",textAlign:"right",}}>websites</aside>
                    <aside style={{width:"100%",textAlign:"right",color:"#737373"}}>on Scroll</aside>
            </Desc>
            </main>
                <LineaBg  src={scrollBG}/>
                <div style={{width:'100%',textAlign:'center'}}>
                   <LineaBg2  src={scrollBG}/>
                </div>
            </Inner>
            <Data>
              {/* <LogoCard /> */}
              <Footer onClick={(e)=>onEnterNextPage(e)}>
                {/* Omni-chain Solution Powered  */}
                Powered  by  
                <img src={lock} alt="" />
                LayerZero
                {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}></mark>
                <img style={{height:'16px',width:'auto',marginBottom:'5px'}} src={linea} alt="" /> */}
              </Footer>
          </Data>
        </Container>
    )
}