
// eslint-disable-next-line react-hooks/exhaustive-deps
import styled from "styled-components";
import scrollLogo from './assets/scroll/scroll3.png';
import {Linea} from './lineaHomepage';
import { Cards } from "./comoments/LineaCard/index";
import { useEffect, useRef, useCallback ,useState} from "react";
import { useWindowSize } from 'react-use';



const AppContainer = styled.div`
width:100% ;
    height:100% ;
    background-color:#101010 ;
    position:relative ;
    /* overflow:auto ; */
 `
const HeaderWrap = styled.header`
position:fixed;
left:50%;
transform:translateX(-50%);
width: 100%;
padding:23px 5vw 0 5vw;
height:76px;
display: flex;
justify-content: space-between;
align-items: center;
background-color:#101010 ;
z-index:999;
`;

const LogoWrap = styled.div`
width: 320px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap:nowrap;

font-size:30px ;
font-weight:600 ;
color:${props=>props.color};
`;

const Logo = styled.img`
width:20px ;
height:auto ;
margin-right:5px ;
text-align:middle ;

`;
const LogoText = styled.div`
padding-top:1px ;
font-size: 22px;
font-family: Roboto-Medium;
font-weight: 500;
color:#fff;
@media screen and (max-width: 750px){
font-size:14px ;
word-break: keep-all;
}
`
const AppButton = styled.button`
width: 171px;
height: 40px;
border-radius: 6px;
filter: blur(0px);
border: 1px solid #fff;
background: transparent;
white-space:pre-wrap ;
color:#000;
overflow:hidden ;



&:hover {
  cursor: pointer;
  background-color:#EB7104;
  color:#fff;
}

a{
  color:#fff;
  display:inline-block;
  width:100%;
  height:100%;
  font-size:18px;
  line-height:40px;
  font-family: Roboto-Regular;
  font-weight:normal;
  @media screen and (max-width: 720px){
    font-size: 16px;
  }

  @media screen and (max-width: 428px){
    font-size: 14px;
  }


  &:hover {
  cursor: pointer;
  background-color:#EB7104 ;
  color:#fff;
}
}
`;

const Pages = styled.div`
width:100vw;
height:100% ;
position:relative ;
`
let clickScrolling=false;


let currentPage=1;
function App() {
  const { height ,width} = useWindowSize();
  const wrapperRef = useRef();
//   const [currentPage, setCurrentPage] = useState(1);
const [page,setPage]=useState(1);

console.log(height);


  const debounce = useCallback((func, delay) => {
    let timer;
    return function( ...args) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }, []);

  const onEnterNextPage=(event)=>{
    if(!clickScrolling){
         event.stopPropagation();
         clickScrolling=true;
         const wrapper = wrapperRef.current;
         wrapper.style.transform = `translate3d(0px, -${height - 80}px, 0px)`;
         wrapper.style.transition = `all 800ms cubic-bezier(.34,.86,.71,.95) 0s`;
         currentPage=2;
         setTimeout(()=>{
             clickScrolling=false;
         },800)
    }
 }

  useEffect(()=>{
      const wrapper = wrapperRef.current;
      if(currentPage===1){
          wrapper.style.transform = `translate3d(0px, 0px, 0px)`;
      }else if(currentPage===2){
          wrapper.style.transform = `translate3d(0px, -${height - 80}px, 0px)`;
      }
  },[height])


  useEffect(() => {
    const scrollDown = () => {
        if (!clickScrolling) {
          //clickScrolling = true;
          const wrapper = wrapperRef.current;
          if (currentPage === 1) {
           if(height>660) wrapper.style.transform = `translate3d(0px, -${height - 80}px, 0px)`;
           if(height<=660)wrapper.style.transform = `translate3d(0px, -${height}px, 0px)`;
            wrapper.style.transition = `all 800ms cubic-bezier(.34,.86,.71,.95) 0s`;
            setTimeout(() => {
              currentPage = 2;
              clickScrolling = false;
            }, 800);
            return;
          }
    
          if (currentPage === 2) {
           if(width>850){
            wrapper.style.transform = `translate3d(0px, -${
                height - 80 + 400
              }px, 0px)`;
           }else if(width<=850 && width>750){
            wrapper.style.transform = `translate3d(0px, -${
                height - 80 + 420
              }px, 0px)`;
           }else{
            wrapper.style.transform = `translate3d(0px, -${
                height + 360
              }px, 0px)`;
           }
            wrapper.style.transition = `all 600ms cubic-bezier(.34,.86,.71,.95) 0s`;
            setTimeout(() => {
              currentPage = 3;
              setPage(3);
              clickScrolling = false;
            }, 600);
          }
        }
      }

      const scrollUp = () => {
        if (!clickScrolling) {
          //clickScrolling = true;
          const wrapper = wrapperRef.current;
          const partners = document.getElementById("list");
          if (currentPage === 2) {
            wrapper.style.transform = `translate3d(0px, 0px, 0px)`;
            wrapper.style.transition = `all 800ms cubic-bezier(.34,.86,.71,.95) 0s`;
            // setCurrentPage(1)
            //setCurrentPage(1);
            setTimeout(() => {
              currentPage = 1;
              clickScrolling = false;
            }, 800);
            return;
          }
    
          if (currentPage === 3 && partners.scrollTop === 0) {
            wrapper.style.transform = `translate3d(0px, -${height - 80}px, 0px)`;
            wrapper.style.transition = `all 300ms cubic-bezier(.34,.86,.71,.95) 0s`;
            setTimeout(() => {
              currentPage = 2;
              setPage(2);
              clickScrolling = false;
            }, 300);
            return;
          }
        }
      }
    


      let scrollFunc = function (e) {
          let el = e || window.event;
              el.stopPropagation();
              if (el.wheelDelta) {
                  if (el.wheelDelta > 0) { //当鼠标滚轮向上滚动时
                      debounce(scrollUp(), 300);
                  }
                  if (el.wheelDelta < 0) { //当鼠标滚轮向下滚动时
                      debounce(scrollDown(), 300);
                  }
              } else if (e.detail) {
                  if (el.detail < 0) { //当鼠标滚轮向上滚动时
                    debounce(scrollUp(), 300);
                  }
                  if (el.detail > 0) { //当鼠标滚轮向下滚动时
                    debounce(scrollDown(), 300);
                  }
              }
      }
      // 给页面绑定鼠标滚轮事件,针对火狐的非标准事件 
       window.addEventListener("DOMMouseScroll", debounce(scrollFunc,100)) // 给页面绑定鼠标滚轮事件，针对Google，mousewheel非标准事件已被弃用，请使用 wheel事件代替
       window.addEventListener("wheel", debounce(scrollFunc,100))   // ie不支持wheel事件，若一定要兼容，可使用mousewheel
       window.addEventListener("mousewheel", debounce(scrollFunc,100))

       let startY = 0; // 触摸起始位置的 y 坐标
       let endY = 0; // 触摸结束位置的 y 坐标

        // 监听 touchstart 事件
        document.addEventListener('touchstart', function(e) {
          startY = e.touches[0].pageY;
        });

        // 监听 touchend 事件
        document.addEventListener('touchend', function(e) {
         endY = e.changedTouches[0].pageY;
         const deltaY = endY - startY; // 计算 y 坐标的差值
        if (deltaY > 0) {
            debounce(scrollUp(), 300);
        } else if (deltaY < 0) {
            debounce(scrollDown(), 300);
        }
      });
    }, [debounce,height,width])

    return (
        <AppContainer >
            <HeaderWrap>
                <LogoWrap>
                    <Logo src={scrollLogo}></Logo>
                    <LogoText>Scroll Name Service</LogoText>
                </LogoWrap>
                <AppButton
                    onClick={(e) => { 
                    e.stopPropagation();
                    window.location.href = "https://app.scrollns.com" 
                }}>
                    <a href="https://app.scrollns.com">LAUNCH  APP</a>
                </AppButton>
            </HeaderWrap>
           <Pages  ref={wrapperRef}>
              <Linea onEnterNextPage={onEnterNextPage}/>
              <Cards page={page}/>
           </Pages>
        </AppContainer>
    );
}

export default App;
